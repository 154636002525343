<template>
  <div class="MainBox" @click.stop="SelectActive(-1, $self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 普通行业 -->
    <div class="box FloatBox" v-if="tradeType != 1">
      <div class="FormStyleTwoLeft FloatBoxRow">
        <ul class="FormStyleTwo">
          <viewer @inited="inited" ref="viewer">
            <li class="FormStyleTwoImgBox FormStyleTwoRow">
              <div class='FormStyleTwoImg backImg' v-if="FormDatas.img"
                :style="{ backgroundImage: 'url(' + FormDatas.img + ')' }">
                <img v-if="FormDatas.img" class="tableImgShow" :src="FormDatas.img" @click="PreviewImgs">
                <span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg"></span>
              </div>
              <div class='FormStyleTwoImg backImg' v-else></div>
              <div class="FormStyleTwoImgMore">
                <p class="hint" style="width:230px">{{ $t('c.Common.ImageSize') }}</p>
                <p class="btnStyleTwo blueBackColor" style="display:table;" @click="HandleModel(1, true)">
                  {{ $t('b.Material.UploadImages') }}</p>
              </div>
            </li>
          </viewer>
          <!-- <li class="FormStyleTwoRow" v-if="btype == 1">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>{{$t('c.Common.OIDAuthentication')}}（{{$t('c.Common.GlobalIdentity')}}）</p>
            <p>
              <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                <div>{{$t('c.Common.'+SelectArr[0].datas[SelectArr[0].ix]+'')}}</div>
                <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="$t('c.Common.'+item+'')" @click="SelectData(0,index)"></li>
                </ul>
                <span class="icon iconfont iconicon-test22 OptionStyleIcon"></span>
              </div>
            </p>
          </li> -->
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>{{ $t('c.Common.ProductName') }}<a
                v-if="FormDatas.is_OID == 1">(OID备案产品不能修改)</a></p>
            <p><input :placeholder="$t('b.ProductManage.HintOne')" v-model="FormDatas.name"
                :disabled="FormDatas.is_OID == 1" /></p>
          </li>
          <li class="FormStyleTwoRow" v-if="FormDatas.is_OID == 1">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>产品标示<a>(OID备案产品ID)</a></p>
            <p><input v-model="FormDatas.organunit_OID" :disabled="FormDatas.is_OID == 1" /></p>
          </li>
          <li class="FormStyleTwoRow" v-if="FormDatas.is_OID == 1">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>产品分类<a>(OID备案产品才会分类)</a></p>
            <p><input v-model="Classify" :disabled="FormDatas.is_OID == 1" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{ $t('b.ProductManage.ShelfLife') }}</p>
            <p><input :placeholder="$t('b.ProductManage.HintTwo')" v-model="FormDatas.period" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{ $t('b.ProductManage.Specification') }}</p>
            <p><input :placeholder="$t('b.ProductManage.HintThree')" v-model="FormDatas.unit" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{ $t('b.ProductManage.Score') }}</p>
            <p><input :placeholder="$t('b.ProductManage.HintThree')" v-model="FormDatas.score" /></p>
          </li>

          <!-- 仅管理员 -->
          <template v-if="['10'].includes(role)">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">{{ $t('b.ProductManage.Keyword') }}</p>
              <p><input placeholder="多个关键词请用英文逗号分割" v-model="FormDatas.keyword" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">{{ $t('b.ProductManage.TraceRatio') }}</p>
              <p><input placeholder="可输入溯源率" v-model="FormDatas.trace_ratio" /></p>
            </li>
          </template>
          
          <li class="FormStyleTwoRow" v-if="isShow">
            <p class="FormStyleTwoTitle">规格码</p>
            <p><input :placeholder="$t('b.ProductManage.HintThree')" v-model="FormDatas.stock_unit" /></p>
          </li>
          <li class="FormStyleTwoRow" v-if="isShow">
            <p class="FormStyleTwoTitle">总库存</p>
            <p><input :placeholder="$t('b.ProductManage.HintThree')" v-model="FormDatas.stock" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{ $t('c.Common.Describe') }}</p>
            <p>
              <textarea class="form-control" rows="3" v-model="FormDatas.introduce"
                :placeholder="$t('b.ProductManage.HintFour')"></textarea>
            </p>
          </li>
        </ul>
      </div>


      <div class="FormStyleTwoRight FloatBoxRow">
        
        <!-- 仅管理员 -->
        <el-tabs v-model="activeName" type="card" v-if="['10'].includes(role)">
          <el-tab-pane label="其它属性" name="custom"></el-tab-pane>
          <el-tab-pane label="产品成分" name="ingredients"></el-tab-pane>
          <el-tab-pane label="检测报告" name="inspection"></el-tab-pane>
        </el-tabs>

        <div class="hint">{{ $t('b.ProductManage.LineHint') }}</div>

        <!-- 其它属性 -->
        <template v-if="activeName === 'custom'">
          <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item, index) in FormDatas.attribute" v-bind:key="index">
            <li class="FormStyleTwo" style="padding:2px 0;">
              <input class="FormStyleTwoInputLeft" :placeholder="$t('b.ProductManage.HintFive')" v-model="item.key" />
              <input class="FormStyleTwoInputRight" :placeholder="$t('b.ProductManage.HintSix')" v-model="item.value" />
              <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
                <a class="btnStyleTwo btnRadius blueBackColor"
                  @click="HandleModel(2, true, index)">{{ $t('c.Common.Image') }}</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{ $t('c.Common.Delete') }}</a>
              </p>
            </li>
          </ul>
        </template>

        <!-- 产品成分 -->
        <template v-if="activeName === 'ingredients'">
          <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item, index) in FormDatas.applet_attribute.ingredients" v-bind:key="index">
            <li class="FormStyleTwo" style="padding:2px 0;">
              <input class="FormStyleTwoInputLeft" :placeholder="$t('b.ProductManage.HintFive')" v-model="item.key" />
              <input class="FormStyleTwoInputRight" :placeholder="$t('b.ProductManage.HintSix')" v-model="item.value" />
              <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
                <a class="btnStyleTwo btnRadius blueBackColor"
                  @click="HandleModel(2, true, index)">{{ $t('c.Common.Image') }}</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{ $t('c.Common.Delete') }}</a>
              </p>
            </li>
          </ul>
        </template>

        <!-- 检测报告 -->
        <template v-if="activeName === 'inspection'">
          <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item, index) in FormDatas.applet_attribute.inspection" v-bind:key="index">
            <li class="FormStyleTwo" style="padding:2px 0;">
              <input class="FormStyleTwoInputLeft" :placeholder="$t('b.ProductManage.HintFive')" v-model="item.key" />
              <input class="FormStyleTwoInputRight" :placeholder="$t('b.ProductManage.HintSix')" v-model="item.value" />
              <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
                <a class="btnStyleTwo btnRadius blueBackColor"
                  @click="HandleModel(2, true, index)">{{ $t('c.Common.Image') }}</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{ $t('c.Common.Delete') }}</a>
              </p>
            </li>
          </ul>
        </template>

        <!-- 添加属性按钮 -->
        <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="addAttributeRow">
          <span class="icon iconfont iconicon-test20"></span>{{ $t('b.ProductManage.AddLine') }}
        </div>

      </div>

    </div>
    <!-- 普通行业 end -->

    <!-- 农药行业 -->
    <div class="box FloatBox" v-if="tradeType == 1">
      <div class="FormStyleTwoLeft FloatBoxRow">
        <ul class="FormStyleTwo">
          <viewer @inited="inited" ref="viewer">
            <li class="FormStyleTwoImgBox FormStyleTwoRow">
              <div class='FormStyleTwoImg backImg' v-if="FormDatas.img"
                :style="{ backgroundImage: 'url(' + FormDatas.img + ')' }">
                <img v-if="FormDatas.img" class="tableImgShow" :src="FormDatas.img" @click="PreviewImgs">
                <span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg"></span>
              </div>
              <div class='FormStyleTwoImg backImg' v-else></div>
              <div class="FormStyleTwoImgMore">
                <p class="hint" style="width:230px">{{ $t('c.Common.ImageSize') }}</p>
                <p class="btnStyleTwo btnRadius blueBackColor" style="display:table;" @click="HandleModel(1, true)">
                  {{ $t('b.Material.UploadImages') }}</p>
              </div>
            </li>
          </viewer>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>农药登记类别</p>
            <p>
            <div class="SelectStyle" @click.stop="SelectActive(2, $self)">
              <div>{{ SelectArr[2].datas[SelectArr[2].ix] }}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                <li class="OptionStyleRow" v-for="(item, index) in SelectArr[2].datas" v-bind:key="index" v-text="item"
                  @click="SelectData(2, index)"></li>
              </ul>
              <span class="icon iconfont iconicon-test22 OptionStyleIcon"></span>
            </div>
            </p>
          </li>
          <li class="FormStyleTwoRow">
            <p style="color:red" class="FormStyleTwoTitle"><i class="redColor">*</i>生码登记证号后六位<a>（少于6位 请点击 <a
                  href="http://oid.plus/reg.html" class="FocusA" target="view_window">登记证号查询</a>）</a></p>
            <p><input placeholder="请输入登记证号后六位" maxlength="6" type="number" v-model="FormDatas.reg_code" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>登记证号</p>
            <p><input placeholder="如与生码登记证号一致可不填" v-model="FormDatas.reg_code_str" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>登记证号持有人</p>
            <p><input placeholder="请输入登记证号持有人名称" v-model="FormDatas.reg_code_owner" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">农药名称</p>
            <p><input placeholder="请输入农药名称" v-model="FormDatas.alias_name" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>{{ $t('c.Common.ProductName') }}<a
                v-if="FormDatas.is_OID == 1">(OID备案产品不能修改)</a></p>
            <p><input placeholder="请输入产品名称" v-model="FormDatas.name" :disabled="FormDatas.is_OID == 1" /></p>
          </li>
        </ul>
      </div>

      <div class="FormStyleTwoLeft FloatBoxRow">
        <ul class="FormStyleTwo">
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>产品规格码</p>
            <p>
            <div class='FormInputArr'>
              <input class="FormInputArrRow" maxlength="1" v-model="code1" v-on:focus="CodeFun(1, 1)"
                v-on:blur="CodeFun(2, 1)" />
              <input class="FormInputArrRow" maxlength="1" v-model="code2" v-on:focus="CodeFun(1, 2)"
                v-on:blur="CodeFun(2, 2)" />
              <input class="FormInputArrRow" maxlength="1" v-model="code3" v-on:focus="CodeFun(1, 3)"
                v-on:blur="CodeFun(2, 3)" />
              <div class="clear"></div>
            </div>
            </p>
          </li>
          <li class="FormStyleTwoRow" v-if="FormDatas.is_OID == 1">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>产品标示<a>(OID备案产品ID)</a></p>
            <p><input placeholder="请输入产品名称" v-model="FormDatas.organunit_OID" :disabled="FormDatas.is_OID == 1" /></p>
          </li>
          <li class="FormStyleTwoRow" v-if="FormDatas.is_OID == 1">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>产品分类<a>(OID备案产品才会分类)</a></p>
            <p><input placeholder="请输入产品名称" v-model="Classify" :disabled="FormDatas.is_OID == 1" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{ $t('b.ProductManage.ShelfLife') }}</p>
            <p><input placeholder="可输入产品保质期" v-model="FormDatas.period" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{ $t('b.ProductManage.Specification') }}</p>
            <p><input placeholder="可输入产品规格" v-model="FormDatas.unit" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{ $t('b.ProductManage.Score') }}</p>
            <p><input placeholder="可输入产品积分" v-model="FormDatas.score" /></p>
          </li>
          
          <!-- 仅管理员 -->
          <template v-if="['10'].includes(role)">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">{{ $t('b.ProductManage.Keyword') }}</p>
              <p><input placeholder="多个关键词请用英文逗号分割" v-model="FormDatas.keyword" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">{{ $t('b.ProductManage.TraceRatio') }}</p>
              <p><input placeholder="可输入溯源率" v-model="FormDatas.trace_ratio" /></p>
            </li>
          </template>

          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{ $t('c.Common.Describe') }}</p>
            <p>
              <textarea class="form-control" rows="3" v-model="FormDatas.introduce" placeholder="可输入产品描述介绍"></textarea>
            </p>
          </li>
        </ul>
      </div>

      <div class="FormStyleTwoRight FloatBoxRow">

        <!-- 仅管理员 -->
        <el-tabs v-model="activeName" type="card" v-if="['10'].includes(role)">
          <el-tab-pane label="其它属性" name="custom"></el-tab-pane>
          <el-tab-pane label="产品成分" name="ingredients"></el-tab-pane>
          <el-tab-pane label="检测报告" name="inspection"></el-tab-pane>
        </el-tabs>
       
        <div class="hint">可根据产品的需求添加更多自定义的属性内容</div>

         <!-- 其它属性 -->
        <template v-if="activeName === 'custom'">
          <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item, index) in FormDatas.attribute" v-bind:key="index">
            <li class="FormStyleTwo">
              <input class="FormStyleTwoInputLeft" placeholder="自定义名称 例：价格" v-model="item.key" />
              <input class="FormStyleTwoInputRight" placeholder="自定义内容 例：99元" v-model="item.value" />
              <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(2, true, index)">图片选择</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">删除</a>
              </p>
            </li>
          </ul>
        </template>

        <!-- 产品成分 -->
        <template v-if="activeName === 'ingredients'">
          <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item, index) in FormDatas.applet_attribute.ingredients" v-bind:key="index">
            <li class="FormStyleTwo" style="padding:2px 0;">
              <input class="FormStyleTwoInputLeft" :placeholder="$t('b.ProductManage.HintFive')" v-model="item.key" />
              <input class="FormStyleTwoInputRight" :placeholder="$t('b.ProductManage.HintSix')" v-model="item.value" />
              <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
                <a class="btnStyleTwo btnRadius blueBackColor"
                  @click="HandleModel(2, true, index)">{{ $t('c.Common.Image') }}</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{ $t('c.Common.Delete') }}</a>
              </p>
            </li>
          </ul>
        </template>

        <!-- 检测报告 -->
        <template v-if="activeName === 'inspection'">
          <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item, index) in FormDatas.applet_attribute.inspection" v-bind:key="index">
            <li class="FormStyleTwo" style="padding:2px 0;">
              <input class="FormStyleTwoInputLeft" :placeholder="$t('b.ProductManage.HintFive')" v-model="item.key" />
              <input class="FormStyleTwoInputRight" :placeholder="$t('b.ProductManage.HintSix')" v-model="item.value" />
              <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
                <a class="btnStyleTwo btnRadius blueBackColor"
                  @click="HandleModel(2, true, index)">{{ $t('c.Common.Image') }}</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{ $t('c.Common.Delete') }}</a>
              </p>
            </li>
          </ul>
        </template>

        <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="addAttributeRow"><span
            class="icon iconfont iconicon-test20"></span>添加自定义标签</div>
      </div>
      
    </div>

    <!-- OID商品备案 -->
    <div class="OIDProductBox NoSelect"
      v-if="SelectArr[0].ix == 1 && OIDDatas[0].datas.length > 0 && FormDatas.is_OID != 1">
      <div>OID码：<i class="OIDProductID redColor" v-text="OID"></i>备注：<i class="redColor">下方选择区允许左右拉动</i></div>
      <div class="OIDProductMain">
        <div class="OIDProductLeft">
          <div class="OIDProductLeftRow" v-for="(item, index) in OIDDatas" v-bind:key="index"
            v-on:click="ClickOIDNav(index, item.id)">
            <img class="OIDProductLeftIcon" :src="'static/images/' + item.icon">
            <p class="OIDProductLeftTitle" v-text="item.title"></p>
          </div>
          <img class="OIDProductLeftRowArrows" :style="{ top: leftIconTop + 'px' }" src="static/images/leftSmall.png">
        </div>
        <div class="OIDProductRight swiper-container" id="category">
          <div class="OIDProductRightBox swiper-wrapper">
            <div class="swiper-slide idboxRightMain">
              <span>请选择用途</span>
              <div class="ibrmBox CodeUseMain">
                <p v-for="(item, index) in OIDDatas[navix].datas" v-bind:key="index" v-text="item.codeuse_name"
                  @click="GetCodeUseList(1, item.codeuse_name, item.codeuse_code, index)" :class="{ activeNav: item.active }">
                </p>
              </div>
            </div>
            <div class="swiper-slide idboxRightMain" v-for="(item, index) in OIDSubDatas" v-bind:key="index"
              v-if="item.length > 0">
              <span>请选择<i class="redColor" v-text="OIDName[index]"></i>用途</span>
              <div class="ibrmBox CodeUseMain">
                <p v-for="(val, ix) in item" v-bind:key="ix" v-text="val.industrycategory_name"
                  @click="GetCodeUseList(2, val.industrycategory_name, val.industrycategory_id, index, ix, val.industrycategory_code)"
                  :class="{ activeNav: val.active }"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="btnArray formFootBtnArr">
      <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(1)"><span
          class="icon iconfont iconicon-test17"></span>{{ $t('c.Common.Save') }}</a>
      <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span
          class="icon iconfont iconicon-test18"></span>{{ $t('c.Common.Back') }}</a>
    </div>

    <!-- 图片空间 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          图片空间
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span>上传图片
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple
                accept="image/png,image/jpeg,image/gif,image/jpg" />
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click.stop="SelectActive(1, $self)" v-if="SelectArr[1].datas.length > 0">
              <div class="SelectStyleValue">{{ SelectArr[1].datas[SelectArr[1].ix].group_name }}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                <ul class="OptionStyleMain">
                  <li class="OptionStyleRow" v-for="(item, index) in SelectArr[1].datas" v-bind:key="index"
                    v-text="item.group_name" @click="SelectData(1, index, item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox ModalMainImgBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
          <div v-else :class="{ ModalMainBoxScroll: imgsList.length > 5 }">
            <table class="table">
              <tbody class="TRHoverStyle">
                <tr v-for="(item, index) in imgsList" v-bind:key="index" @click="selectImg(item.url)">
                  <td class="ModalMaiImgMainBox">
                    <div class="ModalMaiImgMain backImg" :style="{ backgroundImage: 'url(' + item.url + ')' }"></div>
                  </td>
                  <td v-text="item.name"></td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(2)">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

  </div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Column from '@/components/common/Column.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
import Swiper from 'swiper'
export default {
  name: 'HandleProduct',
  components: {
    'K-Paging': Paging,
    'K-Column': Column,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data() {
    return {
      $self: null,
      page: 1,
      id: null,  //产品ID
      role: 30,
      copy: null,
      tradeType: null,
      totalPages: 0,
      uploadType: null,
      uploadImgIndex: null,
      subHint: '数据加载中...',
      isLoding: true,
      isShow: false,
      btype: null,
      column: [
        { name: 'ProductManage', url: 'ProductManage' }
      ],
      SelectArr: [{
        ix: 0,
        active: false,
        datas: ["no", "yes"]
      }, {
        ix: 0,
        active: false,
        datas: []
      }, {
        ix: 0,
        active: false,
        datas: ["登记类别代码为PD", "登记类别代码为WP", "临时登记", "正式登记"]
      }],
      navix: 0,
      leftIconTop: 25,
      ModelShow: false,
      OIDDatas: [{
        id: 3,
        title: "标识物",
        icon: "icon_01_.jpg",
        datas: []
      }, {
        id: 2,
        title: "标识事",
        icon: "icon_02.jpg",
        datas: []
      }, {
        id: 1,
        title: "标识人",
        icon: "icon_03.jpg",
        datas: []
      }],
      OIDSubDatas: [],
      OID: null,
      OIDName: [],
      OIDSelect: [],
      Classify: null,
      codeuse_code: null,
      industrycategory_id: null,
      industrycategory_code: null,
      pid: 0, //分组id
      imgsList: null,
      loading: false,
      Toast: {
        state: false,
        type: 0,
        txt: null
      },
      Confirm: {
        state: false,
        txt: null,
        style: 1
      },
      FormDatas: {
        img: null,
        name: null,
        period: null,
        unit: null,
        introduce: null,
        reg_code: null,
        reg_code_str: null,
        reg_code_owner: null,
        product_code: null,
        alias_name: null,
        keyword: null,
        trace_ratio: null,
        attribute: [],
        applet_attribute: {
          ingredients: [],
          inspection: []
        }
      },
      code1: 0,
      code2: 0,
      code3: 0,
      activeName: 'custom'
    }
  },
  created() {
    self = this;
    self.$self = this;
    self.role = localStorage.getItem("wwmxt_Role");
    self.OID = localStorage.getItem("wwmxt_OID");

    //普通行业
    if(['30'].includes(self.role)){
      self.tradeType = localStorage.getItem("wwmxt_TradeType");  //行业类型
    }

    //管理员-组织显示
    if (self.role && parseInt(self.role) < 30) {
      self.isShow = true;
    }

    self.btype = localStorage.getItem("wwmxt_BType");  //商户类型
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if (this.$route.query.id) {
      self.id = self.$route.query.id;
      self.copy = self.$route.query.copy;
      self.column[1] = { name: 'EditProduct', url: 'ProductEdit?id=' + self.$route.query.id };
      self.GetData();
    } else {
      self.column[1] = { name: 'AddProduct', url: 'ProductEdit' };
    }
    self.GetPacketList();
    self.GetImgsList();
  },
  watch: {
    'FormDatas.reg_code': function (newValue, oldValue) {
      if (newValue.length > 6) {
        this.FormDatas.reg_code = oldValue
      }
    }
  }
  ,
  updated() {
    var category = new Swiper('#category', {
      slidesPerView: 3.5,
      slidesPerGroup: 1,
      spaceBetween: 10,
      slidesOffsetBefore: 30,
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    })
  },
  methods: {
    ChangePage(data) {  //上下翻页
      if (data.te == 1) {
        if (self.page > 1) {
          self.page--
          self.GetImgsList();
        }
      } else {
        if (self.page < self.totalPages) {
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage(data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    SelectData(ix, six, id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      if (ix == 0) {
        if (six == 1) {
          // self.GetCodeUse();  //获取OID商品备案码
        }
      } else if (ix == 1) {  //列表分组选择
        self.page = 1;  //重置页数
        self.totalPages = null;  //清空分页总数
        self.pid = id;  //清楚分组ID
        self.imgsList = null;  //清除图片列表
        self.GetImgsList();  //获取空间图片列表
      }
    },
    GetData() {
      self.loading = true;
      axios.get(self.GetServerUrl(1) + "production/" + self.id)
        .then(function (response) {
          let datas = response.data.meta;
          self.loading = false;

          //管理员-判定农药行业产品
          if(['10'].includes(self.role) && datas.reg_code){
            self.tradeType = 1;
          }
          console.log('tradeType', datas.reg_code, self.tradeType)

          if (datas.attribute) {
            datas.attribute = JSON.parse(datas.attribute);

            //兼容三代系统导出
            let attributeArray = [];
            datas.attribute.forEach(item => {
              if (item.data && item.data.length > 0) {
                item.data.forEach(value => {
                  attributeArray.push({ key: value.name, value: value.value });
                })
              }
            })
            if (attributeArray && attributeArray.length > 0) {
              datas.attribute = attributeArray;
            }
          }else{
            datas.attribute = [];
          }

          //小程序自定义属性
          if(datas.applet_attribute){
            datas.applet_attribute = JSON.parse(datas.applet_attribute);
          }else{
            datas.applet_attribute = {
              ingredients: [],
              inspection: []
            }
          }
          
          console.log(datas)

          //农药登记类别
          if (datas.reg_code_type > 0) {
            self.SelectArr[2].ix = datas.reg_code_type - 1
          }

          var NumStr = datas.product_code;
          if (NumStr) {
            if (NumStr.length == 3) {
              self.code1 = NumStr.substring(0, 1);
              self.code2 = NumStr.substring(1, 2);
              self.code3 = NumStr.substring(2, 3);
            } else if (NumStr.length == 2) {
              self.code1 = 0;
              self.code2 = NumStr.substring(0, 1);
              self.code3 = NumStr.substring(1, 2);
            } else {
              self.code1 = 0;
              self.code2 = 0;
              self.code3 = NumStr;
            }
          }

          //获取商品备案分类
          if (datas.id_code_class_ify) {
            let cls = JSON.parse(datas.id_code_class_ify);
            let str = "";
            for (let i = 0; i < cls.length; i++) {
              if (i < cls.length - 1) {
                str += cls[i].name + ' - ';
              } else {
                str += cls[i].name;
              }
            }
            self.Classify = str;  //备案分类
          }

          console.log(datas)
          self.FormDatas = datas;
          self.SelectArr[0].ix = datas.is_OID;  //是否OID认证状态
          self.GetCodeUse();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2, self.ErrorHint(error));  //弹窗提醒
        })
    },
    ClickOIDNav(ix, id) {
      self.navix = ix;
      for (let item in self.OIDDatas) {
        self.OIDDatas[item].icon = "icon_0" + (parseInt(item) + 1) + ".jpg"
      }
      self.OIDDatas[ix].icon = "icon_0" + (parseInt(ix) + 1) + "_.jpg"
      if (ix == 0) {
        self.leftIconTop = 25;
      } else if (ix == 1) {
        self.leftIconTop = 135;
      } else if (ix == 2) {
        self.leftIconTop = 250;
      }
    },
    SubmitForm(te) {
      if (te == 1) {
        let datas = self.FormDatas;
        let te = "post";

        if (!self.FormDatas.name) {
          self.ToastFun(1, "产品名称不能为空！");
          return;
        }

        //OID
        datas.is_idcode = self.SelectArr[0].ix;
        if (self.SelectArr[0].ix == 1 && !self.FormDatas.organunit_OID) {
          if (self.codeuse_code && self.industrycategory_id && self.industrycategory_code) {
            datas.codeuse_code = self.codeuse_code;
            datas.industrycategory_id = self.industrycategory_id;
            datas.industrycategory_code = self.industrycategory_code;
            datas.idcodeClassify = self.OIDSelect;
          } else {
            self.ToastFun(1, "请选择OID商品用途！");  //弹窗提醒
            return;
          }
        }

        //农药行业
        if (self.tradeType == 1) {
          if (!datas.reg_code) {
            self.ToastFun(1, "登记证号后六位不能为空！");
            return;
          }
          if (datas.reg_code.length < 6) {
            self.ToastFun(1, "登记证号后六位不能小于6位数！");
            return;
          }
          if (!datas.reg_code_str) {
            self.ToastFun(1, "登记证号不能为空！");
            return;
          }
          if (!datas.reg_code_owner) {
            self.ToastFun(1, "登记证持有人不能为空！");
            return;
          }
          if (!datas.reg_code_owner) {
            self.ToastFun(1, "登记证持有人不能为空！");
            return;
          }

          datas.reg_code_type = self.SelectArr[2].ix + 1;
          datas.product_code = self.code1 + "" + self.code2 + "" + self.code3;
        }

        //编辑
        if (self.id && !self.copy) {
          te = "patch";
          datas.production_id = self.id;
        }

        //复制
        if (self.copy) {
          delete datas.production_id
          delete datas.created_at
        }

        self.loading = true;
        axios({
          method: te,
          url: self.GetServerUrl(1) + "production",
          data: datas
        }).then(function (response) {
          self.loading = false;
          self.Confirm.style = 1;
          self.Confirm.state = true;
          self.Confirm.txt = response.data.message + "，是否确定返回列表？"
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2, self.ErrorHint(error));  //弹窗提醒
        })
      } else {
        self.ModelShow = false;
      }
    },
    HandleModel(te, status, ix) {  //隐藏显示生码模态框
      self.uploadType = te;
      self.ModelShow = status;
      self.uploadImgIndex = ix;
    },
    GetPacketList(id) {
      axios.get(self.GetServerUrl(1) + "space_group_list/img")
        .then(function (response) {
          self.SelectArr[1].datas = response.data.meta
          self.SelectArr[1].datas.unshift({ space_group_id: 0, group_name: "默认分组" });
        }).catch(function (error) {
          self.SelectArr[1].datas.unshift({ space_group_id: 0, group_name: "默认分组" });
        })
    },
    GetImgsList() {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1) + "resource/img/" + self.pid + "/" + self.page)
        .then(function (response) {
          let datas = [];
          for (let item in response.data.meta.data) {
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
        }).catch(function (error) {
          self.subHint = self.ErrorHint(error);
          self.isLoding = false;
          self.imgsList = null;
        })
    },
    ToastFun(type, txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast() {
      self.Toast.state = false;
    },
    //上传图片
    AddImg() {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type", "img");
      FormDatas.append("space_group_id", self.pid);
      for (let i = 0; i < inputDOM.files.length; i++) {
        FormDatas.append("resource[]", inputDOM.files[i]);
      }

      axios({
        method: "post",
        url: self.GetServerUrl(1) + "resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: FormDatas
      }).then(function (response) {
        self.loading = false;
        if (self.fileLength > response.data.meta.length) {
          self.ToastFun(2, "文件必须为图片格式");  //弹窗提醒
        } else {
          self.ToastFun(0, response.data.message);  //弹窗提醒
        }
        self.GetImgsList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2, self.ErrorHint(error));  //弹窗提醒
      })
    },
    //选择图片
    selectImg(img) {
      if (self.uploadType == 1) {
        self.FormDatas.img = img;
      } else {
        if(this.activeName === 'custom'){
          self.FormDatas.attribute[self.uploadImgIndex].value = img;
        }else if(this.activeName === 'ingredients'){
          self.FormDatas.applet_attribute.ingredients[self.uploadImgIndex].value = img;
        }else if(this.activeName === 'inspection'){
          self.FormDatas.applet_attribute.inspection[self.uploadImgIndex].value = img;
        }
      }
      self.HandleModel(false);
    },
    //添加属性
    addAttributeRow() {
      if(this.activeName === 'custom'){
        self.FormDatas.attribute.push({ key: "", value: "" });
      }else if(this.activeName === 'ingredients'){
        self.FormDatas.applet_attribute.ingredients.push({ key: "", value: "" });
      }else if(this.activeName === 'inspection'){
        self.FormDatas.applet_attribute.inspection.push({ key: "", value: "" });
      }
    },
    //删除属性
    RemoveRow(ix) {
      if(this.activeName === 'custom'){
        self.FormDatas.attribute.splice(ix, 1);
      }else if(this.activeName === 'ingredients'){
        self.FormDatas.applet_attribute.ingredients.splice(ix, 1);
      }else if(this.activeName === 'inspection'){
        self.FormDatas.applet_attribute.inspection.splice(ix, 1);
      }
    },
    GetCodeUse(id) {
      self.loading = true;
      axios.get(self.GetServerUrl(1) + "code_use")
        .then(function (response) {
          let datas = [{ datas: [] }, { datas: [] }, { datas: [] }];
          for (let item in response.data.meta) {
            if (response.data.meta[item].type_id > 0 && response.data.meta[item].type_id < 4) {
              response.data.meta[item].active = false;
              datas[response.data.meta[item].type_id - 1].datas.push(response.data.meta[item]);
            }
          }
          self.OIDDatas[2].datas = datas[0].datas;
          self.OIDDatas[1].datas = datas[1].datas;
          self.OIDDatas[0].datas = datas[2].datas;
          self.loading = false;
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2, self.ErrorHint(error));  //弹窗提醒
        })
    },
    GetCodeUseList(rank, name, id, ix, six, code) {
      if (rank == 1) {
        self.OID = localStorage.getItem("wwmxt_OID") + "/" + id + ".";
        self.codeuse_code = id;
        for (let item in self.OIDDatas[self.navix].datas) {
          self.OIDDatas[self.navix].datas[item].active = false;
        }
        self.OIDDatas[self.navix].datas[ix].active = true;  //激活
      } else {
        for (let item in self.OIDSubDatas[ix]) {
          self.OIDSubDatas[ix][item].active = false;
        }
        self.OIDSubDatas[ix][six].active = true;  //子分类激活
      }

      self.loading = true;
      axios.get(self.GetServerUrl(1) + "industrycategory/" + rank + "/" + id)
        .then(function (response) {
          if (rank == 1) {
            let datas = response.data.meta;
            for (let item in datas) {
              datas[item].active = false;
            }
            self.OIDSubDatas = [datas]; //选中子类数组
            self.OIDSelect = [{ id: id, name: name }];  //选中用途存储
            self.OIDName = [self.OIDDatas[self.navix].datas[ix].codeuse_name];  //设置用途名称
          } else {
            for (let item in self.OIDSubDatas) {
              if (item > ix) {
                self.OIDSubDatas.splice(item, 1);  //删除子类数组
              }
            }
            let datas = response.data.meta;
            for (let item in datas) {
              datas[item].active = false;
            }
            self.OIDSubDatas[ix + 1] = datas;
            self.OIDName[ix + 1] = self.OIDSubDatas[ix][six].industrycategory_name;  //设置用途名称

            self.OIDSelect[ix + 1] = { id: id, name: name };  //选中用途存储
          }
          self.loading = false;
        }).catch(function (error) {
          self.OIDSubDatas.splice(ix + 1, 1);  //删除多余子类
          self.OIDSelect.splice(ix + 1, 1);  //删除多余用途
          self.OIDName.push(ix + 1, 1);
          //设置OID备案ID
          if (rank == 2) {
            for (let item in self.OIDDatas[self.navix].datas) {
              if (self.OIDDatas[self.navix].datas[item].active) {
                self.OID = localStorage.getItem("wwmxt_OID") + "/" + self.OIDDatas[self.navix].datas[item].codeuse_code + "." + id;
                self.industrycategory_id = id;
                self.industrycategory_code = code;
                self.OIDSelect[ix + 1] = { id: id, name: name };  //选中用途存储
              }
            }
          }
          self.loading = false;
          self.ToastFun(1, self.ErrorHint(error));  //弹窗提醒
        })
    },
    ConfirmBackFun(data) {
      self.Confirm.state = data.state;
      if (data.state) {
        self.Confirm.state = false;
        javascript: history.back();
      }
    },
    RemoveImg() {
      self.FormDatas.img = null;
    },
    inited(viewer) {
      self.$viewer = viewer
    },
    PreviewImgs() {
      self.$viewer.show();
      self.$viewer.update();
    },
    CodeFun(te, ix) {
      if (te == 1) {
        eval("self.code" + ix + "= null");
      } else {
        if (!eval("self.code" + ix)) {
          eval("self.code" + ix + "= 0");
        }
      }
    }
  }
}
</script>

<style scoped>
.SelectStyle {
  width: 300px;
}

.OIDProductBox {
  padding: 20px;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
}

.OIDProductID {
  padding-right: 20px;
}

.OIDProductMain {
  overflow: hidden;
  margin-top: 20px;
}

.OIDProductLeft {
  float: left;
  position: relative;
  padding-right: 40px;
  border-right: 2px solid #278BF5;
}

.OIDProductLeftRow {
  margin-top: 20px;
  text-align: center;
  width: 70px;
  cursor: pointer;
}

.OIDProductLeftRow:first-child {
  margin-top: 0;
}

.OIDProductLeftIcon {
  width: 65px;
}

.OIDProductLeftRowArrows {
  position: absolute;
  right: 0;
  top: 0;
  width: 8px;
}

.OIDProductRight {
  width: calc(100% - 120px);
}

.idboxRightMain {
  width: 220px !important;
  height: 316px !important;
  border: 1px solid #e3e3e3;
}

.idboxRight i {
  color: #f30;
}

.ibrmBox {
  padding: 10px;
  height: 257px;
  overflow-y: scroll;
}

.idboxRightMain span {
  font-weight: bold;
  padding: 10px;
  display: block;
  font-size: 0.95em;
  background-color: #EEEEEE;
  border-bottom: 1px solid #e3e3e3;
}

.idboxRightMain p {
  border-bottom: 1px solid #eee;
  padding: 8px 10px;
  font-size: 0.85em;
  color: #666;
  cursor: pointer;
}

.idboxRightMain p:hover {
  background-color: #EEEEEE !important;
}

.OIDProductLeftTitle {
  margin-top: 5px;
  color: #585A64;
}

.activeNav {
  background-color: #EEE;
}

.FormInputArrRow:first-child {
  margin-left: 0;
}

.FormInputArrRow {
  width: 76px;
  margin-left: 10px;
}</style>
