import { render, staticRenderFns } from "./BarCode.vue?vue&type=template&id=533da216&scoped=true"
import script from "./BarCode.vue?vue&type=script&lang=js"
export * from "./BarCode.vue?vue&type=script&lang=js"
import style0 from "./BarCode.vue?vue&type=style&index=0&id=533da216&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533da216",
  null
  
)

export default component.exports